import { getDebugger } from '../libs/debug'
import { getPandaServices } from '../libs/pandats'
import { MiliCron } from '@jakguru/milicron'
import * as Sentry from '@sentry/browser'
import { fireToGtm } from './tracking'
const debug = getDebugger('Customizations')
const afdb = getDebugger('AppsFlyer', '#000000', '#00C2FF')
const cron = new MiliCron()
cron.start()

let currentAsset: string | undefined
let cuid: string | undefined

const changeThemeColorCHKBX = (trigger: 'load' | 'click') => {
  debug('changeThemeColorCHKBX', trigger)
  let $bodyWrapper = window.$('#trading-platform-page')
  let $colorSwitcher = window.$('#theme-color-switcher')
  let defaultTheme = 'theme-dark' // when checkbox UNCHECKED
  let secondTheme = 'theme-white' // when checkbox CHECKED
  let currentTheme = localStorage.getItem('panda-forex__theme')

  $bodyWrapper.addClass(currentTheme)

  if (trigger === 'load') {
    if (currentTheme === defaultTheme) {
      $colorSwitcher.prop('checked', false)
    } else {
      $colorSwitcher.prop('checked', true)
    }
  } else if (trigger === 'click') {
    if (currentTheme === defaultTheme) {
      window.runPlugin('forexSwitchTheme', { theme: secondTheme })
    } else {
      window.runPlugin('forexSwitchTheme', { theme: defaultTheme })
    }
  }
}

const onUserIdentified = (userId: number) => {
  if (0 === userId) {
    return onUserUnidentified()
  }
  cuid = userId.toString()
  Sentry.setUser({ id: userId.toString() })
  if (window.AF) {
    afdb(`Setting Customer ID to ${userId}`)
    window.AF('pba', 'setCustomerUserId', userId.toString())
  } else {
    afdb('AppsFlyer SDK not found')
  }
  fireToGtm('login', userId.toString(), {
    method: 'form',
  })
}

const onUserUnidentified = () => {
  fireToGtm('logout', cuid)
  cuid = undefined
  Sentry.setUser({ id: undefined })
  if (window.AF) {
    afdb(`Unsetting Customer ID Attribution`)
    window.AF('pba', 'setCustomerUserId', '')
  } else {
    afdb('AppsFlyer SDK not found')
  }
}

const getCurrentAsset = () => {
  const services = getPandaServices()
  const stateService = services.get('stateService')
  if (!stateService) {
    return undefined
  }
  const selectedAsset = stateService.get('selectedAsset')
  if (!selectedAsset) {
    return undefined
  }
  if (selectedAsset.value && 'string' === typeof selectedAsset.value.s) {
    return selectedAsset.value.s
  }
  return undefined
}

const getCurrentAssetInformation = async () => {
  const services = getPandaServices()
  const stateService = services.get('stateService')
  if (!stateService) {
    return undefined
  }
  const selectedAsset = stateService.get('selectedAsset')
  if (!selectedAsset) {
    return undefined
  }
  if (selectedAsset.value && 'string' === typeof selectedAsset.value.s) {
    return selectedAsset.value
  }
  return undefined
}

const onLoad = () => {
  if (window.bnxsMain) {
    debug('Main Afrimarkets Forex Thread Loaded')
    window.bnxsMain.on('forex:platformShow', () => {
      window.$('#tradingPlatform').fadeIn(500)
    })
    window.bnxsMain.on('forex:platformHide', () => {
      window.$('#tradingPlatform').fadeOut(250)
    })
    window.bnxsMain.on('forex:loginSuccess', (data: any) => {
      if (data && data.customer && data.customer.rawId) {
        if ('number' === typeof data.customer.rawId) {
          onUserIdentified(data.customer.rawId)
        } else {
          debug(`data.customer.rawId is not a number. Got type ${typeof data.customer.rawId}`)
        }
      }
      // @ts-ignore
      if (window._MChatIIFE) {
        if (data && data.customer) {
          /**
           * Ensure that we're only dealing with clients who have funded their accounts
           */
          if (data.customer.funded) {
            debug('Logging in Embedded Chat')
            // @ts-ignore
            window._MChatIIFE.authentication.login(data.customer.customerId)
          }
        } else {
          debug('Logging out Embedded Chat')
          // @ts-ignore
          window._MChatIIFE.authentication.logout()
        }
      }
    })
    window.bnxsMain.on('forex:logout', () => {
      onUserUnidentified()
      // @ts-ignore
      if (window._MChatIIFE) {
        // @ts-ignore
        window._MChatIIFE.authentication.logout()
      }
    })
    window.bnxsMain.on('forex:init', () => {
      changeThemeColorCHKBX('load')
      const services = getPandaServices()
      const userService = services.get('userService')
      if (userService && userService.user) {
        if ('number' === typeof userService.user.rawId) {
          onUserIdentified(userService.user.rawId)
        } else
          debug(`userService.user.rawId is not a number. Got type ${typeof userService.user.rawId}`)
      }
    })
    window.bnxsMain.once('forex:init', () => {
      const asset = getCurrentAsset()
      currentAsset = asset
      cron.$on('* * * * * * *', () => {
        const asset = getCurrentAsset()
        if (asset !== currentAsset) {
          currentAsset = asset
          debug('Asset Changed:', asset)
          // Sentry.captureEvent({
          //   message: 'Asset Changed',
          //   extra: {
          //     asset,
          //   },
          // })
          if (window.AF) {
            afdb(`Sending 'view asset' event with symbol ${asset}`)
            window.AF('pba', 'event', {
              eventType: 'EVENT',
              eventName: 'view asset',
              eventValue: { symbol: asset || 'none' },
            })
          }
          getCurrentAssetInformation().then((a) => {
            if (!a) {
              return
            }
            fireToGtm('view_item', cuid, {
              items: [
                {
                  item_id: `${a.s}`,
                  item_name: `${a.displayName}`,
                  index: `${a.displayOrder}`,
                  item_brand: 'Afrimarkets',
                  item_category: `${a.category}`,
                },
              ],
            })
          })
          // we should notify appsflyer of the asset change
        }
      })
    })
    window.bnxsMain.on('forex:themeChange', (data: any) => {
      if (data && data.theme) {
        const { theme } = data
        debug('Theme Change:', theme)
        // Sentry.captureEvent({
        //   message: 'Theme Changed',
        //   extra: {
        //     theme,
        //   },
        // })
        switch (theme) {
          case 'theme-white':
            window.$('body').addClass('theme-white').removeClass('theme-dark')
            break
          case 'theme-dark':
            window.$('body').addClass('theme-dark').removeClass('theme-white')
            break
        }
      }
    })
    // window.bnxsMain.on('forex:depositSuccess', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Deposit Success',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:depositFail', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Deposit Fail',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:signupSuccess', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Signup Success',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:signupFail', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Signup Fail',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:loginSuccess', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Login Success',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:loginFail', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Login Fail',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:logout', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Logout',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:init', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Forex Init',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:platformHide', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Platform Hide',
    //     extra: data,
    //   })
    // })
    // window.bnxsMain.on('forex:platformShow', (data: any) => {
    //   Sentry.captureEvent({
    //     message: 'Platform Show',
    //     extra: data,
    //   })
    // })
  } else {
    debug('Main Afrimarkets Forex Thread Not Loaded')
  }
}

window.changeThemeColorCHKBX = changeThemeColorCHKBX

window.addEventListener('bnxs:loaded', () => {
  onLoad()
})

if (window.bnxsMain) {
  onLoad()
}
